import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import * as s from './academySpeakers.module.css';


const Speaker = ({ block }) => {
  const image = {
    data: block.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.image?.altText || ``,
  };
  const companyLogo = {
    data: block.companyLogo?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.companyLogo?.altText || ``,
  };
  return (
    <>
      <div className={`justify-content-between h-100 ${s.singleSpeaker}`}>
        <div className='d-flex flex-column align-items-center'>
          {!!image.data &&
            <GatsbyImage
              loading="eager"
              placeholder="dominantColor"
              image={image.data}
              alt={image.alt}
              className={`${s.avatar}`} />
          }
          {!!block.name && <h3 className={s.name}>{block.name}</h3>}
          {!!block.position && <h4 className={s.position}>{block.position}</h4>}
        </div>
        {!!companyLogo.data &&
          <GatsbyImage
            loading="eager"
            placeholder="dominantColor"
            image={companyLogo.data}
            alt={companyLogo.alt}
            className={`${s.companyLogo}`} />
        }
      </div>
    </>
  )
}

const AcademySpeakers = ({ block }) => {
  console.log(block)
  return (
    <>
      {block?.show &&
        <section className={s.speakersSection}>
          <div className="container">
            {!!block.title && <div>
              <h2 className={s.title}>{block.title}</h2>
            </div>}
            <div className={`row justify-content-center`} style={{ rowGap: '30px' }}>
              {!!block.items && block.items.map((item, index) => (
                <div className="col-md-6 col-xl-3" key={index} >
                  <Speaker block={item} />
                </div>
              ))}
            </div>
          </div>
        </section>
      }
    </>
  )
}
export default AcademySpeakers;
