import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import parse from 'html-react-parser';
import * as s from './AcademyWhoCanJoin.module.css';

const AcademyWhoCanJoin = ({ block }) => {
  return (
    <>
      {block?.show &&
        <section className={`${s.whoCanJoin} position-relative`}>
          <StaticImage
            placeholder="none"
            loading="eager"
            alt="BackgroundArtboard"
            src="./images/artboard.png"
            className={s.backgroundImage}
          />
          <div className="container">
            {!!block.title && <h2 className={s.sectionTitle}>{block.title}</h2>}
            <div className={`row ${s.joinRow}`}>
              {!!block.blocks && block.blocks.map((item, index) => (
                <div className="col-lg-6" key={index}>
                  <JoinBlockItem block={item} />
                </div>
              ))}
            </div>
          </div>
        </section>
      }
    </>
  )
}
export default AcademyWhoCanJoin;
const JoinBlockItem = ({ block }) => {
  const style = { color: !!block.titleColor ? block.titleColor : '#fff' };
  return (
    <div className={`${s.joinBlockItem} position-relative h-100`}>
      {!!block.showScholarshipIcon && <StaticImage
        placeholder="none"
        loading="eager"
        alt="BackgroundArtboard"
        src="./images/scolarshipIcon.png"
        className={s.marker}
      />}
      {!!block.title && <h3 className={s.title} style={style}>{block.title}</h3>}
      {!!block.content && <div className={s.content}>{parse(block.content)}</div>}
    </div>
  )
}