import { GatsbyImage } from 'gatsby-plugin-image';
import parse from "html-react-parser";
import React from 'react';
import YoutubeVideo from '../../youtubeVideo/youtubeVideo';
import AcademyInfo from './AcademyInfo';
import * as s from './academyHero.module.css';

const AcademyHero = ({ block }) => {
  const BGImage = {
    data: block.bgImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.bgImage?.altText || ``,
  };

  const logoImage = {
    data: block.logoImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.logoImage?.altText || ``,
  };

  const placeholderImage = {
    data: block.placeholderImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.placeholderImage?.altText || ``,
  };

  let placeholderImageUrl = "";
  if (!!block.youtubeVideoId) {
    if (!!placeholderImage?.data) {
      placeholderImageUrl = placeholderImage
    } else {
      placeholderImageUrl = `https://img.youtube.com/vi/${block.youtubeVideoId}/maxresdefault.jpg`;
    }
  }

  return (
    <>
      {block?.show &&
        <section className={s.heroSection}>
          {!!BGImage.data &&
            <GatsbyImage
              loading="eager"
              placeholder="dominantColor"
              image={BGImage.data}
              alt={BGImage.alt}
              className={`${s.backgroundImage}`} />
          }
          <div className={`${s.heroWrapper} position-relative`}>
            <div className='container position-relative'>
              <div className="row">
                <div className="col-lg-6">
                  {!!logoImage.data &&
                    <>
                      <img loading="lazy" src={logoImage.data.images.fallback.src} alt={logoImage.alt} className={``} style={{ maxWidth: '275px' }} />
                      {/* <GatsbyImage
                        loading="eager"
                        placeholder="blurred"
                        // placeholder="none"
                        // backgroundColor="transparent"
                        image={logoImage.data}
                        alt={logoImage.alt}
                        style={{maxWidth: '275px'}}
                        className={``} /> */}
                    </>
                  }
                  {!!block.title && <h1 className={s.sectionTitle}>
                    {block.title}
                  </h1>}
                  {!!block.description && <div className={s.sectionDescription}>
                    {parse(block.description)}
                  </div>}
                  {!!block?.ctaDescriptionText && <div className={s.ctaDescription}>{block?.ctaDescriptionText}</div>}
                  <a href='#applySection' className={`mb-4 mb-lg-0 ${s.ctaBtn}`}>{!!block.ctaButtonText ? block?.ctaButtonText : 'Join our Academy NOW'}</a>
                </div>
                <div className="col-lg-6">
                  {!!block.youtubeVideoId &&
                    <YoutubeVideo imageUrl={placeholderImageUrl} videoId={block.youtubeVideoId} wrapperClasses={s.youtubeVideo} />
                  }
                  {(!block.youtubeVideoId && !!placeholderImage.data) &&
                    <GatsbyImage
                      loading="eager"
                      placeholder="dominantColor"
                      image={placeholderImage.data}
                      alt={placeholderImage.alt}
                      className={`mw-100`}
                      style={{ borderRadius: '10px' }} />
                  }
                </div>
              </div>
            </div>
          </div>
          <AcademyInfo block={block.academyInfo} />
        </section>
      }
    </>
  )
}
export default AcademyHero;