import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import parse from 'html-react-parser';
import React from 'react';
import * as s from './academyJoin.module.css';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const AcademyJoin = ({ block }) => {
  const breakpoints = useBreakpoint();

  const rightImage = {
    data: block.rightImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.rightImage?.altText || ``,
  };

  return (
    <>
      {block?.show &&
        <section className={s.joinSection}>

          <div className="container position-relative">
            <div className="row justify-content-between">
              <div className="col-lg-6 d-flex flex-column justify-content-center position-relative">
                <StaticImage
                  placeholder="none"
                  loading="eager"
                  alt="Join"
                  src="./images/backgroundGradient.png"
                  className={s.backgroundImage}
                />
                <div className="position-relative" style={{ zIndex: 1 }}>
                  <h3>{block.title}</h3>
                  <div>{parse(block.description)}</div>
                  <a href='#applySection' className={s.ctaBtn}>Join Us!</a>
                </div>
              </div>
              {breakpoints.lg &&
                <div className="col-lg-5">
                  {!!rightImage.data &&
                    <GatsbyImage
                      loading="eager"
                      placeholder="dominantColor"
                      image={rightImage.data}
                      alt={rightImage.alt}
                      className={`mw-100`} />
                  }
                </div>
              }
            </div>
          </div>
        </section>
      }
    </>
  )
}
export default AcademyJoin;