import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import * as s from './academyFaq.module.css';
import { BreakpointProvider, useBreakpoint } from 'gatsby-plugin-breakpoints';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import YoutubeVideo from '../../youtubeVideo/youtubeVideo';
const AcademyFaq = ({ block }) => {
  const breakpoints = useBreakpoint();

  const settingsCommon = {
    dots: false,
    arrows: false,
    // infinite: false,
    // slidesToShow: 3.8,
    infinite: true,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: '60px',
    responsive: [
      {
        breakpoint: 991.9,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: '60px',
        },
      },
      {
        breakpoint: 767.9,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: '30px',
        },
      },
      {
        breakpoint: 575.9,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '30px',
        },
      }
    ],
  }

  const rightImage = {
    data: block.rightImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.rightImage?.altText || ``,
  };

  return (
    <>
      {block?.show &&
        <section className={s.faq}>
          <div className="container">
            {!breakpoints.lg && <div className="d-flex justify-content-between align-items-center">
              {!!block.title && <h2 className={s.sectionTitle}>{block.title}</h2>}
              <div className={s.sectionImage}>
                {!!rightImage.data &&
                  <GatsbyImage
                    loading="eager"
                    placeholder="dominantColor"
                    image={rightImage.data}
                    alt={rightImage.alt}
                    className={`mw-100`} />
                }
              </div>
            </div>
            }
            {breakpoints.lg && !!block.title && <h2 className={s.sectionTitle}>{block.title}</h2>}

            <div className="row">
              <div className="col-lg-6">
                <div className={s.accordionWrapper}>
                  <Accordion data={block.questions} />
                </div>
              </div>
              {breakpoints.lg && <div className="col-lg-6">
                {!!rightImage.data &&
                  <GatsbyImage
                    loading="eager"
                    placeholder="dominantColor"
                    image={rightImage.data}
                    alt={rightImage.alt}
                    className={`mw-100`} />
                }
              </div>}
            </div>
          </div >

          {/* <div className={`academy-faq-slider p-s-container`}> */}
          {breakpoints.lg
            ?
            <div className='container'>
              <div className='row justify-content-center'>
                {block.desktopSlider.map((item, i) => {
                  return (
                    <div key={`slider-item-${i}`} className={`col-xl-4 col-lg-6`}>
                      <SliderVideo data={item} wrapperClasses={s.youtubeVideoPlaceholderDesktop} autoplay={1} />
                    </div>
                  )
                })}
              </div>
            </div>
            : <div className={``}>
              <Slider {...settingsCommon}>
                {
                  block.mobileSlider.map((item, i) => {
                    return (
                      <div key={`slider-item-${i}`} className={`px-2 ${s.videoSlideItem}`}>
                        <SliderVideo data={item} wrapperClasses={s.youtubeVideoPlaceholder1} />
                      </div>
                    )
                  })
                }
              </Slider>
            </div>
          }
        </section>
      }
    </>
  )
}
export default AcademyFaq

const Accordion = ({ data }) => {
  const [openItemIndex, setOpenItemIndex] = useState(0); // Set the default index to 0 for the first item

  const toggleItem = (index) => {
    setOpenItemIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div>
      {data.map((item, index) => (
        <AccordionItem
          key={index}
          question={item.question}
          answer={item.answer}
          isOpen={openItemIndex === index}
          onClick={() => toggleItem(index)}
        />
      ))}
    </div>
  );
};

const AccordionItem = ({ question, answer, isOpen, onClick }) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick();
    }
  };
  return (
    <div className={s.accordionItem}>
      <div
        role="button"
        tabIndex="0" // Make the item focusable
        onClick={onClick}
        onKeyDown={handleKeyDown} >
        <div className={s.question}>
          <span className={s.togglerIcon}>
            {isOpen ? '-' : '+'}
          </span>
          <span className={s.marker}>Q:</span>
          <div>
            {question}
          </div>
        </div>
      </div>
      {isOpen && <div className={`${s.answer} d-flex`}><span className={s.marker}>A:</span><div>{answer}</div></div>}
    </div>
  );
};

const SliderVideo = ({ data, sectionClasses, wrapperClasses, autoplay }) => {
  if (undefined == autoplay) {
    autoplay = 1;
  }
  const image = {
    data: data?.placeholderImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: data?.placeholderImage?.altText || ``,
  };
  return (
    <>
      <YoutubeVideo autoplay={autoplay} imageUrl={image} videoId={data.youtubeVideoId} sectionClasses={sectionClasses} wrapperClasses={wrapperClasses} />
    </>
  )
}