import parse from 'html-react-parser';
import React from 'react';
import * as s from './AcademyApply.module.css';
import ApplyBlock from './AcademySingleApplyBlock';

const AcademyApply = ({ block }) => {
  // const style = { color: block.deadline.deadlineColor ? block.deadline.deadlineColor : '#F5A173' }
  return (
    <>
      {block?.show &&
        <section className={s.applicationSection}>
          <div className="container">
            <div className={s.wrapper}>
              {!!block.title && <h2 className={s.sectionTitle}>
                {block.title}
              </h2>}
              {!!block.description && <div className={s.description}>{parse(block.description)}</div>}
              {!!block.deadline && <div className={s.deadline}>
                <div className={s.deadlineTitle}>APPLICATION DEADLINE:</div>
                <div className={s.deadlineDate}>{block.deadline}</div>
              </div>}

              <div className={`row justify-content-center ${s.applyRow}`}>
                {block.blocks.map((item, index) => (
                  <div className="col-lg-6" key={index}>
                    <ApplyBlock block={item} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      }
    </>
  )
}

export default AcademyApply
