import React from 'react';
import * as s from './AcademySignleApplyBlock.module.css';

const AcademySingleApplyBlock = ({ block }) => {
  console.log(block);
  // const titleStyle = { color: block.title.color ? block.title.color : '#fff' }
  // const buttonStyle = { backgroundColor: block?.applyButton?.backgroundColor ? block?.applyButton?.backgroundColor : '#fff', color: block?.applyButton?.textColor ? block?.applyButton?.textColor : '#000' }
  const titleStyle = {}
  const buttonStyle = {}
  const style = {
    '--single-apply-title-color': block.title.color ? block.title.color : '#fff',
    '--single-apply-button-bg-color': block?.applyButton?.backgroundColor ? block?.applyButton?.backgroundColor : '#1bfed1',
    '--single-apply-button-bg-hover-color': block?.applyButton?.backgroundColorHover ? block?.applyButton?.backgroundColorHover : '#a8ffee',
    '--single-apply-button-text-color': block?.applyButton?.textColor ? block?.applyButton?.textColor : '#000',
  }
  return (
    <>
      <div className={`${s.applyBlock} h-100`} style={style}>
        {!!block.title?.text && <h2 className={s.blockTitle} style={titleStyle}>{block?.title?.text}</h2>}
        {!!block.description && <p className={s.blockDescription}>{block.description}</p>}
        {!!block?.buttonDescriptionText && <div className={s.blockButtonDescription}>{block?.buttonDescriptionText}</div>}
        {!!block?.applyButton?.link?.url && <a href={block?.applyButton?.link?.url} target={block?.applyButton?.link?.target} className={s.blockButton} style={buttonStyle}>{block?.applyButton?.link?.title}</a>}
      </div>
    </>
  )
}
export default AcademySingleApplyBlock
