// extracted by mini-css-extract-plugin
export var bonusModule = "academyModules-module--bonus-module--71be4";
export var bonusTitle = "academyModules-module--bonus-title--4f8b8";
export var content = "academyModules-module--content--64258";
export var marker = "academyModules-module--marker--57263";
export var moduleSection = "academyModules-module--module-section--9fd43";
export var row = "academyModules-module--row--74bd8";
export var rowGap = "academyModules-module--row-gap--f3564";
export var singleModule = "academyModules-module--single-module--41942";
export var subtitle = "academyModules-module--subtitle--2fe1b";
export var title = "academyModules-module--title--2bcf9";