import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import * as s from './academyPartners.module.css';

const AcademyPartners = ({ block }) => {
  return (
    <>
      {block?.show &&
        <section className={s.growthPartners}>
          <div className="container">
            <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row" style={{gap:'30px'}}>
              {!!block.title && <h2 className={s.sectionTitle}>{block.title}</h2>}
              <div className={`${s.partnersLogosWrapper} d-flex flex-grow-1 justify-content-around`}>
                {block.partners.map((item, index) => (
                  <div className={s.singleLogo} key={index}>
                    {/* {item.logo} */}
                    <PartnerIcon logo={item} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      }
    </>
  )
}

const PartnerIcon = ({ logo }) => {
  const image = {
    data: logo?.localFile?.childImageSharp?.gatsbyImageData,
    alt: logo?.altText || ``,
  };
  return (
    <>
      {image.data &&
        <GatsbyImage
          loading="eager"
          placeholder="dominantColor"
          image={image.data}
          alt={image.alt}
          className={`mw-100 mh-100`} />
      }
    </>
  )
}

export default AcademyPartners