import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import parse from "html-react-parser";
import React from 'react';
import YoutubeVideo from '../../youtubeVideo/youtubeVideo';
import * as s from './academyWhyPartner.module.css';

const AcademyWhyPartner = ({ block }) => {

  const placeholderImage = {
    data: block.placeholderImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.placeholderImage?.altText || ``,
  };

  let placeholderImageUrl = "";
  if (!!block.youtubeVideoId) {
    if (!!placeholderImage?.data) {
      placeholderImageUrl = placeholderImage
    } else {
      placeholderImageUrl = `https://img.youtube.com/vi/${block.youtubeVideoId}/maxresdefault.jpg`;
    }
  }

  return (
    <>
      {block?.show &&
        <section className={s.whyPartnerSection}>
          <div className='container position-relative'>
            <div className="row align-items-center justify-content-between">
              <div className='col-12'>
                <h2 className={s.title}>{block.title}</h2>
              </div>
              <div className="col-lg-6 pe-xl-0 position-relative">
                <StaticImage
                  placeholder="none"
                  loading="eager"
                  alt="Join"
                  src="./images/backgroundGradient.png"
                  className={s.backgroundImage}
                />
                {!!block.content && <div className={s.sectionContent}>
                  {parse(block.content)}
                </div>}
              </div>
              <div className="col-lg-5 ps-xl-0">
                {!!block.youtubeVideoId &&
                  <YoutubeVideo imageUrl={placeholderImageUrl} videoId={block.youtubeVideoId} wrapperClasses={s.youtubeVideo} />
                }
                {(!block.youtubeVideoId && !!placeholderImage.data) &&
                  <GatsbyImage
                    loading="eager"
                    placeholder="dominantColor"
                    image={placeholderImage.data}
                    alt={placeholderImage.alt}
                    className={`mw-100`}
                    style={{ borderRadius: '10px' }} />
                }
              </div>
            </div>
          </div>
        </section>
      }
    </>
  )
}
export default AcademyWhyPartner;