import { graphql } from "gatsby"
import React from "react"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../assets/css/@wordpress/block-library/build-style/style.css"
import "../assets/css/@wordpress/block-library/build-style/theme.css"
import Layout from "./../components/layout"
import Seo from "./../components/seo"
import schemaBuilder from "./../utils/schemaBuilder"
import AcademyHero from "../components/academy/academyHero/AcademyHero"
import AcademyPartners from "../components/academy/academyPartners/Academypartners"
import AcademyJoin from "../components/academy/academyJoin/AcademyJoin"
import AcademyTutors from "../components/academy/academyTutors/AcademyTutors"
import AcademyWhyJoin from "../components/academy/academyWhyJoin/AcademyWhyJoin"
import AcademyWhoCanJoin from "../components/academy/academyWhoCanJoin/AcademyWhoCanJoin"
import AcademyModules from "../components/academy/academyModules/AcademyModules"
import AcademyApply from "../components/academy/academyApply/AcademyApply"
import AcademyFaq from "../components/academy/academyFaq/AcademyFaq"
import AcademySpeakers from "../components/academy/academySpeakers/AcademySpeakers"
import AcademyWhyPartner from "../components/academy/academyWhyPartner/AcademyWhyPartner"

const WebinarTemplate = ({ data: { post, site }, location }) => {

  const schema = schemaBuilder({ academy: post }, 'academy', site);
  const blocks = post?.acfAcademy;

  return (
    <Layout pageTitle={`Academy - ${post.title}`}>
      <Seo
        title={post.title}
        description={post.excerpt}
        pageSeo={post}
        location={location}
        schema={schema}
      />
      <AcademyHero block={blocks?.heroBlock} />
      <AcademyPartners block={blocks?.partnersBlock} />
      <AcademyJoin block={blocks?.joinBlock} />
      <AcademyTutors block={blocks?.tutorsBlock} />
      <AcademyWhyJoin block={blocks?.whyJoinBlock} />
      <AcademyWhoCanJoin block={blocks?.whoCanJoinBlock} />
      <AcademyModules block={blocks?.modulesBlock} />
      <AcademySpeakers block={blocks?.speakersBlock} />
      <AcademyWhyPartner block={blocks?.whyPartnerBlock}/>
      <div id="applySection" style={{ scrollMarginTop: '120px' }}>
        <AcademyApply block={blocks?.applyBlock} />
      </div>
      <AcademyPartners block={blocks?.partnersBlock} />
      <AcademyFaq block={blocks?.faqBlock} />
    </Layout >
  )
}

export default WebinarTemplate

export const pageQuery = graphql`
  query WebinarById(
    $id: String!
  ) {
    site {
      siteMetadata {
        siteUrl: url
        title
      }
    }
    post: wpAcademy(id: { eq: $id }) {
      id
      excerpt
      content
      title
      uri
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
      acfAcademy {
        schemaBlock {
          teaches
          sameAs {
            item
          }
        }
        heroBlock {
          show
          bgImage {
            altText
            localFile {
              childImageSharp {
                  gatsbyImageData
              }
            }
          }
          logoImage {
            altText
            localFile {
              childImageSharp {
                  gatsbyImageData
              }
            }
          }
          title
          description
          ctaButtonText
          ctaDescriptionText
          youtubeVideoId
          placeholderImage {
            altText
            localFile {
              childImageSharp {
                  gatsbyImageData
              }
            }
          }
          academyInfo {
            duration
            level
            location
            frequency
            price
            showScolarshipIcon
          }
        }
        partnersBlock {
          show
          title
          partners {
            altText
            localFile {
              childImageSharp {
                  gatsbyImageData
              }
            }
          }
        }
        joinBlock {
          show
          title
          description
          rightImage {
            altText
            localFile {
              childImageSharp {
                  gatsbyImageData
              }
            }
          }
        }
        tutorsBlock {
          show
          title
          tutors {
            image {
              altText
              localFile {
                childImageSharp {
                    gatsbyImageData
                }
              }
            }
            name
            linkedin
            description
          }
        }
        whyJoinBlock {
          show
          title
          blocks {
            title
            titleColor
            content
          }
        }
        whoCanJoinBlock {
          show
          title
          blocks {
            title
            titleColor
            content
          }
        }
        modulesBlock {
          show
          modules {
            isBonusModule
            title
            subtitle
            content
          }
        }
        speakersBlock {
          show
          title
          items {
            image {
              altText
              localFile {
                childImageSharp {
                    gatsbyImageData
                }
              }
            }
            name
            position
            companyLogo {
              altText
              localFile {
                childImageSharp {
                    gatsbyImageData
                }
              }
            }
          }
        }
        whyPartnerBlock {
          show
          title
          content
          youtubeVideoId
          placeholderImage {
            altText
            localFile {
              childImageSharp {
                  gatsbyImageData
              }
            }
          }
        }
        applyBlock {
          show
          title
          description
          deadline
          blocks {
            title {
              text
              color
            }
            description
            buttonDescriptionText
            applyButton {
              link {
                url
                title
                target
              }
              textColor
              backgroundColor
              backgroundColorHover
            }
          }
        }
        faqBlock {
          show
          title
          rightImage {
            altText
            localFile {
              childImageSharp {
                  gatsbyImageData
              }
            }
          }
          questions {
            question
            answer
          }
          desktopSlider {
            youtubeVideoId
            placeholderImage {
              altText
              localFile {
                childImageSharp {
                    gatsbyImageData
                }
              }
            }
          }
          mobileSlider {
            youtubeVideoId
            placeholderImage {
              altText
              localFile {
                childImageSharp {
                    gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`
