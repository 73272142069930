// extracted by mini-css-extract-plugin
export var accordionItem = "academyFaq-module--accordion-item--74dff";
export var accordionWrapper = "academyFaq-module--accordion-wrapper--4cef1";
export var answer = "academyFaq-module--answer--cb65f";
export var faq = "academyFaq-module--faq--97140";
export var marker = "academyFaq-module--marker--2db87";
export var question = "academyFaq-module--question--30f38";
export var sectionImage = "academyFaq-module--section-image--fc605";
export var sectionTitle = "academyFaq-module--section-title--aa390";
export var togglerIcon = "academyFaq-module--toggler-icon--26cbc";
export var videoSlideItem = "academyFaq-module--video-slide-item--b45af";
export var youtubeVideoPlaceholder1 = "academyFaq-module--youtube-video-placeholder1--ea676";
export var youtubeVideoPlaceholderDesktop = "academyFaq-module--youtube-video-placeholder-desktop--c1818";